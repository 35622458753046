<template>
  <div>
    <Header></Header>
    <div class="content">
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/">首页</router-link>><router-link :to="{name:'Goods_detail',query:{id:goods_id}}">商品详情</router-link>><a href="javascript:;">填写信息</a>
        </div>
      </div>
      <div class="write_info m1440">
        <div class="pt40 pb30 fs24">填写订单信息</div>
        <div class="box">
          <div class="fs20 fwb pb40">商品信息</div>
          <div class="goods_info flex-box">
            <img :src="oss + detail.thumbnail" class="img_about mr20" alt="" />
            <div class="flex-grow-1 flex-box flex-between flex-col-start flex-col info_detail">
              <div class="flex-box flex-between num_price">
                <div class="fs20 fwb">{{ detail.productName }}</div>
                <div class="fs18">数量</div>
              </div>
              <div class="flex-box flex-between num_price">
                <div class="fs28 fwb red">
                  <span class="fs16">¥</span>{{ detail.price_text?detail.price_text:detail.price }}
                  <span class="col9 fs16">{{ detail.unit?detail.unit:'' }}</span>
                </div>
                <div class="fs18">x{{ buyNum? buyNum: 0 }}</div>
              </div>
            </div>
          </div>
          <div class="fs20 fwb pt30 pb40 border">填写信息</div>
          <div class="flex-box pb30">
            <div class="fs18 info_title must">证书抬头</div>
            <div class="flex-grow-1 name_box" v-if="certificate.length <= 0">
              <span style="color: #999,text-align: center" @click="addCertPopup()">您暂无证书抬头,点击进行添加</span>
            </div>
            <div class="flex-grow-1 name_box" v-else>
              <div class="flex-box flex-between pb20">
                <div class="fs16 col10">
                  <span class="col9">中文公司名称：</span><span>{{ choose_cert.companyName }}</span>
                </div>
                <div class="target" v-if="choose_cert.defaultFlag == 1">默认</div>
              </div>
              <div class="fs16 col10 pb20">
                <span class="col9">中文公司详细地址：</span><span>{{ choose_cert.address }}</span>
              </div>
              <div class="fs16 col10 pb20">
                <span class="col9">英文公司名称：</span><span>{{ choose_cert.companyNameEn }}</span>
              </div>
              <div class="fs16 col10 pb20">
                <span class="col9">英文公司详细地址：</span><span>{{ choose_cert.addressEn }}</span>
              </div>
              <div class="flex-box flex-between">
                <div class="fs16 col10">
                  <span class="col9">国家：</span><span>{{ choose_cert.country }}</span>
                </div>
                <div class="fs16 normal use_other" @click="closePop()">使用其他抬头</div>
              </div>
            </div>
          </div>
          <div class="flex-box pb30">
            <div class="fs18 info_title must">绿证消费起止日期/绿证汇报期</div>
            <div class="name_right flex-box flex-between">
              <vue-datepicker-local  v-model="post_data.beginPurchaseDate" placeholder="请选择日期" :local="local"
                format="YYYY-MM-DD" clearable />
              <div class="line"></div>
              <vue-datepicker-local :disabledDate="disabledDate" v-model="post_data.endPurchaseDate" placeholder="请选择日期" :local="local"
                format="YYYY-MM-DD" clearable  />
            </div>
          </div>
          <div class="flex-box pb20">
            <div class="fs18 info_title must">
              用途<span class="fs16">(须用英文填写)</span>
            </div>
            <textarea name="" v-model="post_data.useage" id="" cols="30" rows="10" class="disclosure fs18"
              placeholder="Disclosure for Scope 2 Reporting"></textarea>
          </div>
          <div class="tr pb30 tips fs16">若无特定内容，使用默认内容即可</div>
          <div class="flex-box pb30">
            <div class="fs18 info_title">备注</div>
            <textarea name="" id="" v-model="post_data.remark" cols="30" rows="10" class="disclosure fs18"
              placeholder="请输入备注"></textarea>
          </div>
          <div class="flex-box">
            <div class="fs18 info_title">推荐人/公司</div>
            <input type="text" class="input_rec fs18" v-model="post_data.referee" placeholder="请输入推荐人/公司" />
          </div>
        </div>
        <div class="flex-box agree_box" :class="agreement ? 'on' : ''" @click.stop="agree()">
          <div class="fs18">我已阅读并同意<span @click.stop="getAgreement('SERVICE_AGREEMENT')" class="col_nor">《服务协议》</span>
          </div>
        </div>
        <div class="flex-box agree_box" :class="agreement2 ? 'on' : ''" @click.stop="agree2()">
          <div class="fs18">我已阅读并同意<span @click.stop="getAgreement('PRIVACY_POLICY')" class="col_nor">《隐私政策》</span></div>
        </div>
        <div class="flex-box agree_box" :class="agreement3 ? 'on' : ''" @click.stop="agree3()">
          <div class="fs18">我已阅读并同意<span @click.stop="getAgreement('DISCLAIMERS')" class="col_nor">《免责声明》</span></div>
        </div>
        <div class="price_detail mb40">
          <div class="pb20 flex-box flex-end">
            <span class="fs16">商品总额：</span>
            <span class="price tr fs18">¥{{ total_money }}</span>
          </div>
          <div class="flex-box flex-col-end flex-end">
            <span class="fs16">应付总额：</span>
            <div class="price red tr">
              <span class="fs16">¥</span><span class="fs28">{{ total_money }}</span>
            </div>
          </div>
        </div>
        <div class="submit fs26" v-if="is_first == 0" @click="submit">提交订单</div>
        <div class="submit fs26" v-else>提交订单</div>
      </div>
    </div>
    <!-- 选择证书抬头 -->
    <Popup :visible="infoPop" :confirmText="false" :cancleText="false" @close="closePop">
      <div class="name_pop">
        <div class="flex-box name_title flex-between">
          <div class="fs20">选择证书抬头</div>
          <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="closePop()" alt="" />
        </div>
        <ul class="cirticle_list">
          <li class="flex-box" v-for="(v, k) in certificate" :key="k" @click="choose_certificate(v)">
            <div class="default" v-if="v.defaultFlag == 1">默认</div>
            <div class="agreePop_box" :class="choose_cert.id == v.id ? 'on' : ''"></div>
            <div class="cirticle_box">
              <div class="pb20">
                <span class="fs16 col9">中文公司名称：</span>
                <span class="fs16 col10">{{ v.companyName }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">中文公司详细地址：</span>
                <span class="fs16 col10">{{ v.address }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">英文公司名称：</span>
                <span class="fs16 col10">{{ v.companyNameEn }}</span>
              </div>
              <div class="pb20">
                <span class="fs16 col9">英文公司详细地址：</span>
                <span class="fs16 col10">{{ v.addressEn }}</span>
              </div>
              <div>
                <span class="fs16 col9">国家：</span>
                <span class="fs16 col10">{{ v.country }}</span>
              </div>
            </div>
          </li>
        </ul>
        <Pagination :sumCount="total" :perCount="pageSize" @pageTurn="pageTurn"></Pagination>
        <div class="flex-box flex-between sure_add">
          <div class="sure fs16" @click="closePop()">确定</div>
          <div class="add fs16" @click="addCertPopup()">新增证书抬头</div>
        </div>
      </div>
    </Popup>
    <!-- 增加|删除 -->
    <Popup :visible="addCertAct" :confirmText="false" :cancleText="false" @close="addCertPopup">
      <div class="name_pop">
        <div class="flex-box name_title flex-between">
          <div class="fs20">添加证书抬头</div>
          <img src="../assets/static/icon/icon_close.png" class="icon_close" @click="addCertPopup()" alt="" />
        </div>
        <div class="cirticle_info">
          <div class="must fs16 col9 info_title">中文公司名称(须用中文填写)</div>
          <input type="text" placeholder="请输入中文公司名称" class="input1 plr10" v-model="add_edit.companyName" />
          <div class="must fs16 col9 info_title">中文公司详细地址(须用中文填写)</div>
          <input type="text" class="input2 plr10" placeholder="请输入中文公司详细地址" v-model="add_edit.address" />
           <!-- mao modify 20250314 -->
          <!-- <div class="must fs16 col9 info_title">英文公司名称(须用英文填写)</div> -->
          <div class="fs16 col9 info_title">英文公司名称(购买IREC时，须用英文填写)</div>
          <input type="text" placeholder="请输入英文公司名称" class="input1 plr10" v-model="add_edit.companyNameEn" />
          <!-- <div class="must fs16 col9 info_title">英文公司详细地址(须用英文填写)</div> -->
          <div class="fs16 col9 info_title">英文公司详细地址(购买IREC时，须用英文填写)</div>
          <input type="text" class="input2 plr10" placeholder="请输入英文公司详细地址" v-model="add_edit.addressEn" />
          <div class="must fs16 col9 info_title">国家(须用英文填写)</div>
          <div class="input1 select relative">
            <div class="country" :class="coun_index == '-1' ? 'col9' : ''" @click="showCountries">{{
              add_edit.country ?
                add_edit.country : '请选择'
            }}</div>
            <div class="country_box" v-show="showCountry">
              <div v-for="(item, index) in countries" :key="index" @click="chooseCountry(index)">{{ item.value }}</div>
            </div>
          </div>
          <!-- <input type="text" class="input1" placeholder="请输入国家" v-model="add_edit.country" /> -->
          <div class="flex-box">
            <div class="fs16 col9 mr30">设为默认证书抬头</div>
            <div class="switch" :class="add_edit.defaultFlag == true ? 'on' : ''" @click="setDefault()"></div>
          </div>
          <div class="save" @click="save_data()">保存</div>
        </div>
      </div>
    </Popup>
    <!-- 《协议》 -->
    <Popup :visible="agreement_pop1" :confirmText="false" :cancleText="false" @close="closeAgree">
      <div class="agreement_pop">
        <div class="fs18 tc pb30">《{{articleDetail.contentKey.contentLabel}}》</div>
        <div class="agreement_detail">
          <div v-html="articleDetail.richText"></div>
        </div>
        <div class="agreement_btn" @click="closeAgree">我已知晓</div>
      </div>
    </Popup>
    <Footer></Footer>
  </div>
</template>
<script>
import VueDatepickerLocal from "vue-datepicker-local";
import Pagination from "@/components/pagination.vue";
import cookie from "vue-cookies";
import { Loading, Message, MessageBox } from "element-ui"; // 消息提示
export default {
  components: {
    VueDatepickerLocal,
    Pagination,
  },
  data() {
    return {
      agreement_pop1:false,
      infoPop: false,
      addCertAct: false,
      post_data: {
        beginPurchaseDate: "", // 默认选择当前日期
        endPurchaseDate: "", // 默认选择当前日期
        certificateId: 0, //证书id,
        useage: "Disclosure for Scope 2 Reporting", //用途
        referee: "", //推荐人
        remark: "", //备注
      },
      local: {
        // 定制化日期选择的格式内容
        dow: 1, // Monday is the first day of the week
        yearSuffix: "年", // format of head
        monthsHead: "1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月".split("_"), // months of head
        months: "一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月".split(
          "_"
        ), // months of panel
        weeks: "一_二_三_四_五_六_日".split("_"), // weeks
        cancelTip: "cancel",
        submitTip: "confirm",
      },
      agreement: false,
      agreement2: false,
      agreement3: false,
      detail: {},
      goods_id: 0,
      buyNum: 0,
      oss: this.global.uploadOss,
      certificate: [],
      pageSize: 10, //分页大小
      total: 0, //分页总数
      pageNum: 1, // 分页记录数
      choose_cert: {}, // 选择证书
      add_edit: {
        defaultFlag: "0",
      }, //编辑添加
      total_money: 0,
      is_first: 0,
      countries: [],
      coun_index: "-1",
      showCountry: false,
      articleDetail:{
        contentKey:{}
      }
    };
  },
  watch: {
    date(to, from) {
      // console.log("to:", to);
      // console.log("to:", from);
      if (to === "") {
        this.checkFields("date");
      } else {
        this.checkFocus("date");
      }
    },
  },

  created() {
    this.goods_id = this.$route.params.goods_id;
    this.buyNum = this.$route.params.goods_num;
    this.goods_detail(this.goods_id || 0);
    this.get_certificate();
    this.setting()
  },

  methods: {
    closeAgree(){
      this.agreement_pop1 = false;
      // this.agreement_pop2 = false;
      // this.agreement_pop3 = false;
    },

    getAgreement(type){
      this.agreement_pop1 = true;
      if(type=='SERVICE_AGREEMENT'){
				this.title = '服务协议'
			}else if(type=='PRIVACY_POLICY'){
				this.title = '隐私政策'
			}else{
				this.title = '免责声明'
			}
      this.$api.apiLanguage({contentKeys:type}).then((data_res) => {
        console.log(data_res)
        if (data_res.resCode == 0) {
          this.articleDetail = data_res.root[0]
        } else {
          this.$message.error(data_res.resMsg);
        }
      })
    },


    // 选择其他的 抬头
    choose_certificate(data) {
      this.choose_cert = data;
      this.post_data.certificateId = data.id;
    },

    endPurchaseDate(){
       console.log(1111)
    },

    // 显示隐藏国家
    showCountries() {
      this.showCountry = !this.showCountry;
    },
    // 选择国家
    chooseCountry(index) {
      this.coun_index = index;
      this.showCountry = false;
      let countries = this.countries
      this.add_edit.country = countries[index].value
    },

    //跳转协议
    jump_type(type) {
      this.$router.push("/agreement?type=" + type);
    },

    //提交订单
    submit() {
      let that = this;
      let s_data = that.post_data;
      s_data.productId = that.goods_id;
      s_data.totalCount = (that.buyNum).toString().replace(/,/g, "");
      if (s_data.certificateId <= 0) {
        that.$message.error("请选择抬头证书");
        return;
      }
      if (s_data.beginPurchaseDate == "") {
        that.$message.error("请选择绿证消费开始时间");
        return;
      } else {
        let beg_date = s_data.beginPurchaseDate;
        if (typeof beg_date == "object") {
          let s_Date = this.time(beg_date);
          s_data.beginPurchaseDate = s_Date;
        }
      }
      if (s_data.endPurchaseDate == "") {
        that.$message.error("请选择绿证消费结束时间");
        return;
      } else {
        let end_date = s_data.endPurchaseDate;
        if (typeof end_date == "object") {
          let s_end_Date = this.time(end_date);
          s_data.endPurchaseDate = s_end_Date;
        }
      }
      var oDate1 = new Date(s_data.beginPurchaseDate);
      var oDate2 = new Date(s_data.endPurchaseDate);
      if (oDate1.getTime() > oDate2.getTime()) {
        that.$message.error("开始时间不能大于结束时间");
        return;
      }

      if (s_data.useage == "") {
        that.$message.error("请填写用途");
        return;
      } else {
        if (/.*[\u4e00-\u9fa5]+.*$/.test(s_data.useage)) {
          that.$message.error("用途不能含有汉字！");
          return false;
        }
      }
 // mao add 20240425
      if (s_data.useage.length >1024) {
        that.$message.error("'用途'字符超长!");
        return;
      }
      if (s_data.remark.length >1024) {
        that.$message.error("'备注'字符超长!");
        return;
      }
      if (s_data.referee.length >255) {
        that.$message.error("'推荐人/公司'字符超长!");
        return;
      }
// end
      let s_agreement = that.agreement;
      if (s_agreement == false) {
        that.$message.error("请先同意服务协议");
        return;
      }
      let s_agreement2 = that.agreement2;
      if (s_agreement2 == false) {
        that.$message.error("请先同意隐私政策");
        return;
      }
      let s_agreement3 = that.agreement3;
      if (s_agreement3 == false) {
        that.$message.error("请先同意免责声明");
        return;
      }
      let loading = that.$loading({
        lock: true,
        text: '提交中...',
        spinner: 'el-icon-loading',
      })
      that.is_first = 1
      that.$api.apiCreateOrder(s_data, 'application/json').then((res) => {
        that.is_first = 0
        loading.close();
        if (res.resCode == 0) {
          // console.log(res)
          that.$message.success("提交成功");
          var id = res.root.id
            that.$router.push("/submit_order?code=" + escape('环保桥' + id));
        } else {
          that.$message.error(res.resMsg);
        }
      }).catch((err) => {
        that.is_first = 0
      });
    },
    // 添加|编辑提交
    save_data() {
      let _that = this;
      let add_edit = _that.add_edit;
      if (add_edit.defaultFlag == true) {
        add_edit.defaultFlag = "1";
      } else {
        add_edit.defaultFlag = "0";
      }
      if (!add_edit.companyName) {
        Message.error('请输入中文公司名称')
        return;
      }
      if (!add_edit.address) {
        Message.error('请输入中文公司详细地址')
        return;
      }
      // mao 20250324
      // if (!add_edit.companyNameEn) {
      //   Message.error('请输入英文公司名称')
      //   return;
      // }
      // if (!add_edit.addressEn) {
      //   Message.error('请输入英文详细地址')
      //   return;
      // }
      // end
      if (!add_edit.country) {
        Message.error('国家不能为空')
        return;
      }
      // console.log(add_edit);return;
      let userinfo = cookie.get('huanbaoqiao_userinfo')
      add_edit.userId = userinfo.id
      // // console.log(userinfo);return;
      // if(!userinfo.companyNameEn){
      //   Message.error("请先完善公司信息");
      //   _that.$router.push("/info");
      //   return;
      // }
      // add_edit.companyNameEn = userinfo.companyNameEn
      _that.$api.saveCertificate(add_edit).then((res) => {
        if (res.resCode == 0) {
          _that.$message.success("保存成功");
          _that.add_edit = {}
          _that.add_edit.defaultFlag = "0"
          _that.addCertAct = false;
          _that.get_certificate();
        } else {
          _that.$message.error(res.resMsg);
        }
      });
    },
    // 默认抬头
    setDefault() {
      let _that = this;
      _that.add_edit.defaultFlag = !_that.add_edit.defaultFlag;
    },
    // 添加证书
    addCertPopup() {
      this.addCertAct = !this.addCertAct;
    },
    // 显示选择其他证书
    closePop() {
      this.infoPop = !this.infoPop;
    },
    // 分页
    pageTurn(page) {
      this.pageNum = page + 1;
      this.get_certificate();
    },
    //证书抬头
    get_certificate() {
      var that = this;
      let page = that.pageNum;
      let s_param = {
        deviceType: "1",
        sortProp: "defaultFlag",
        sortType: "DESC",
        page: page,
        limit: that.pageSize,
        deleteFlag:"0"
      };
      that.$api.getCertificate(s_param).then((data_res) => {
        if (data_res.resCode == 0) {
          var totalCount = data_res.totalCount;
          if (JSON.stringify(data_res.root) === "{}" || data_res.root.length <= 0) {
            that.isShowNavBarLoading = true;
            that.total = totalCount;
            that.certificate = data_res.root;
          } else {
            // 判断 是否 加载完毕
            var is_data = true;
            var show_total = that.pageNum * that.pageSize;
            if (totalCount > show_total) {
              //当只有一页的时候展示
              is_data = true;
            } else {
              is_data = false;
            }
            that.total = totalCount;
            that.certificate = data_res.root;
            that.choose_cert = data_res.root[0];
            that.post_data.certificateId = that.choose_cert.id;
            that.isShowNavBarLoading = is_data;
          }

          // that.certificate = res.root;
          // that.total = res.totalCount;
          // if (res.root && res.root.length > 0) {
          //   that.choose_cert = res.root[0];
          //   that.post_data.certificateId = that.choose_cert.id;
          // }
        } else {
          that.$message.error(data_res.resMsg);
        }
      });
    },

    //商品详情
    goods_detail(id) {
      let that = this
      that.$api.apiGoodsDetail({ id: id, deviceType: "1"}).then((res) => {
        if (res.resCode == 0) {
          let s_data = res.root[0];
          s_data.price_text = that.global.addCommas(s_data.price)
          let num = that.buyNum.replace(/,/g, "")
          var total_money =  (parseFloat(s_data.price) * Number(num)).toFixed(2);
          that.total_money = that.global.addCommas(total_money)
          that.detail = s_data;
        } else {
          that.$message.error(res.resMsg);
        }
      });
    },

    //获取国家
    setting() {
      let that = this
      let data = {
        tag: 'EQQUH0BOWWED',
        pkg_children: 'com.dazz.business.model.setting.CommonSetting'
      }
      that.$api.apiPostSetting(data).then((res) => {
        if (res.resCode == 0) {
          if (res.root.length > 0) {
            let root = res.root[0]
            that.countries = root.children
          }
        } else {
          that.$message.error(res.resMsg);
        }
      }).catch((err) => {
        console.log(err);
      });

    },

    time(date) {
      let Y = date.getFullYear(),
        M = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1,
        D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return Y + "-" + M + "-" + D;
    },

    disabledDate(date) {
      if (date.getTime() < this.post_data.beginPurchaseDate) {
        // 当前日期以前的时间禁用
        return true;
      }
    },
    agree() {
      this.agreement = !this.agreement;
    },
    agree2() {
      this.agreement2 = !this.agreement2;
    },
    agree3() {
      this.agreement3 = !this.agreement3;
    },
  },
};
</script>
